import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    OnInit,
    inject,
    signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderComponent } from '@quipex/shared/components';
import {
    DialogService,
    UserApiService
} from '@quipex/shared/data';
import { HasPermissionDirective } from '@quipex/shared/directives';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { AddUserComponent } from '../add-user/add-user.component';

@Component({
    selector: 'qpx-list-users',
    templateUrl: './list-users.component.html',
    styleUrls: ['./list-users.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatTableModule,
        LoaderComponent,
        HasPermissionDirective
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListUsersComponent implements OnInit {
    isLoading = signal(false);
    totalRecords = signal(0);
    dataSource = signal([]);
    usersPayload = signal({
        pageSize: 8,
        pageNo: 1,
        pageIndex: 0, // used for mat-paginator
        searchTerm: ''
    });

    form!: FormGroup;
    displayedColumns: string[] = ['name', 'organizationName', 'role', 'lastLoggedIn', 'createdAt', 'status', 'actions'];

    get userSearchCtrl(): FormControl {
        return this.form.controls['userSearch'] as FormControl;
    }

    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly snackBar = inject(MatSnackBar);
    private readonly userService = inject(UserApiService);
    private readonly dialogService = inject(DialogService);

    ngOnInit(): void {
        this.configureForm();
        this.configureUserSearch();
        this.getUsers();
    }

    navigateToManageUser(id: any): void {
        this.router.navigate(['/admin/users', id]);
    }

    changeUserStatus(user: any): void {
        const modalTitle = user.isActive
            ? 'Disable user'
            : 'Enable user';

        const modalText = user.isActive
            ? 'Are you sure you want to disable this user?'
            : 'Are you sure you want to enable this user?';

        const modalButtonText = user.isActive
            ? 'Disable'
            : 'Enable';

        const confirmationDialogRef = this.dialogService.openConfirmationDialog(modalTitle, modalText, '',
            null, null, modalButtonText, '', true, false);

        confirmationDialogRef.afterClosed()
            .subscribe({
                next: (result) => {
                    if (result === 'confirm') {
                        this.changeStatus(user.isActive, user.identifier);
                    }
                },
            });
    }

    openAddUserModal(): void {
        const dialogRef = this.dialogService.openDialog({
            dialogTitle: 'Add User',
            componentToLoad: AddUserComponent,
            submitButtonLabel: 'Next',
            submitButtonIcon: 'chevron_right',
            submitButtonTheme: 'accent',
            cancelButtonLabel: 'Cancel',
            confirmEnabled: false
        }, ['qpx-dialog', 'add-user']);

        dialogRef.afterClosed()
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.getUsers();
                    }
                }
            });
    }

    handlePageEvent(event: PageEvent): void {
        this.usersPayload.update(payload => {
            return {
                ...payload,
                pageIndex: event.pageIndex,
                pageSize: event.pageSize,
                pageNo: event.pageIndex + 1,
                searchTerm: this.userSearchCtrl.value
            }
        });

        this.getUsers();
    }

    private changeStatus(isActive: boolean, identifier: string): void {
        if (isActive) {
            this.userService.inactivateUser(identifier)
                .subscribe((item) => {
                    if (item) {
                        this.snackBar.open('User disabled!', 'check_circle', {
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                            panelClass: 'success',
                            duration: 1500
                        });
                        this.getUsers();
                    }
                });
        } else {
            this.userService.reactiveateUser(identifier)
                .subscribe((item) => {
                    if (item) {
                        this.snackBar.open('User enabled!', 'check_circle', {
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                            panelClass: 'success',
                            duration: 1500
                        });
                        this.getUsers();
                    }
                });
        }
    }

    private configureForm(): void {
        this.form = this.formBuilder.group({
            userSearch: [''],
        });
    }

    private configureUserSearch(): void {
        this.userSearchCtrl.valueChanges
            .pipe(
                debounceTime(350),
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(() => {
                this.usersPayload.update(payload => {
                    return {
                        ...payload,
                        pageIndex: 0,
                        pageNo: 1,
                        searchTerm: this.userSearchCtrl.value
                    }
                });

                this.getUsers();
            });
    }

    private getUsers(): void {
        this.dataSource.set([]);
        this.isLoading.set(true);
        this.userService.getAllUsers(this.usersPayload())
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe({
                next: (response) => {
                    this.dataSource.set(response?.items ?? []);
                    this.totalRecords.set(response?.totalCount ?? 0);
                }
            });
    }
}
